// import React from "react";
import * as React from "react";
import "./Footer.scss";
import GithubIcon from "../../assets/icons/github.svg";
import LeetcodeIcon from "../../assets/icons/leetcode.svg";
import LinkedInIcon from "../../assets/icons/linkedin.svg";

const Footer = () => {
  return (
    <div className="container">
      <footer className="d-flex flex-wrap justify-content-between align-items-center py-3 my-4 border-top">
        <div className="col-md-4 d-flex align-items-center">
          <a
            href="/"
            className="mb-3 me-2 mb-md-0 text-body-secondary text-decoration-none lh-1"
          >
            <svg className="bi" width="30" height="24">
              {/* <use xlink:href="#bootstrap"></use> */}
            </svg>
          </a>
          <span className="mb-3 mb-md-0 text-body-secondary">
            © Copyright 2024 Eric Lo. All Rights Reserved.
          </span>
        </div>

        <ul className="nav col-md-4 justify-content-end list-unstyled d-flex">
          <li className="ms-3">
            <a
              className="text-body-secondary"
              href="https://github.com/xxkingericxx"
            >
              <img src={GithubIcon} alt="Github" />
            </a>
          </li>
          <li className="ms-3">
            <a
              className="text-body-secondary"
              href="https://leetcode.com/xxkingericxx/"
            >
              <img src={LeetcodeIcon} alt="Leetcode" />
            </a>
          </li>
          <li className="ms-3">
            <a
              className="text-body-secondary"
              href="https://www.linkedin.com/in/eric-lo-p-eng/"
            >
              <img src={LinkedInIcon} alt="LinkedIn" />
            </a>
          </li>
        </ul>
      </footer>
    </div>
  );
};

export default Footer;
