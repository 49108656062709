// import React from "react";
import * as React from "react";
import "./Header.scss";
import { Link, useLocation } from "react-router-dom";
import { useEffect } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Offcanvas from "react-bootstrap/Offcanvas";
import logo from "../../assets/icons/Logo-32x32.png";

const Header = () => {
  const location = useLocation();
  const { pathname } = location;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <header>
      <>
        {[false].map((expand: any) => (
          <Navbar
            key={expand}
            expand={expand}
            className="bg-body-tertiary mb-3"
            collapseOnSelect
            bg="primary"
            data-bs-theme="dark"
            fixed="top"
          >
            <Container fluid>
              <Navbar.Brand href="/">
                <img
                  src={logo}
                  className="img-fluid border rounded-3 shadow-lg mb-4 header__img"
                  alt="Example image"
                  loading="lazy"
                />
              </Navbar.Brand>
              <Navbar.Toggle
                aria-controls={`offcanvasNavbar-expand-${expand}`}
              />
              <Navbar.Offcanvas
                id={`offcanvasNavbar-expand-${expand}`}
                aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                placement="end"
                bg="primary"
                data-bs-theme="dark"
              >
                <Offcanvas.Header closeButton>
                  <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                    Links
                  </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                  <Nav className="justify-content-end flex-grow-1 pe-3 ">
                    <Nav.Link href="/">
                      <Link
                        to={"/"}
                        className={`nav-link px-2 ${
                          pathname === "/" ? "text-white" : "text-secondary"
                        }`}
                      >
                        Home
                      </Link>
                    </Nav.Link>
                    <Nav.Link href="/About">
                      <Link
                        to={"/About"}
                        className={`nav-link px-2 ${
                          pathname === "/About"
                            ? "text-white"
                            : "text-secondary"
                        }`}
                      >
                        About
                      </Link>
                    </Nav.Link>
                    <Nav.Link href="/Projects">
                      <Link
                        to={"/Projects"}
                        className={`nav-link px-2 ${
                          pathname === "/Projects"
                            ? "text-white"
                            : "text-secondary"
                        }`}
                      >
                        Projects
                      </Link>
                    </Nav.Link>
                    <Nav.Link href="/Contact">
                      <Link
                        to={"/Contact"}
                        className={`nav-link px-2 ${
                          pathname === "/Contact"
                            ? "text-white"
                            : "text-secondary"
                        }`}
                      >
                        Contact Me
                      </Link>
                    </Nav.Link>
                  </Nav>
                </Offcanvas.Body>
              </Navbar.Offcanvas>
            </Container>
          </Navbar>
        ))}
      </>
    </header>
  );
};

export default Header;
