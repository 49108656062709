// import React from "react";
import * as React from "react";
import "./ProjectsPage.scss";
import { Button } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { useState } from "react";
import "bootstrap/dist/css/bootstrap.css";
import Carousel from "react-bootstrap/Carousel";
import Axios from "../../assets/icons/Axios.png";
import Chart from "../../assets/icons/Chart.png";
import CSS from "../../assets/icons/CSS.png";
import Dotenv from "../../assets/icons/Dotenv.png";
import Express from "../../assets/icons/Express.png";
import HTML from "../../assets/icons/HTML.png";
import Javascript from "../../assets/icons/Javascript.png";
import Jwt from "../../assets/icons/Jwt.png";
import Knex from "../../assets/icons/Knex.png";
import MySQL from "../../assets/icons/MySQL.png";
import Node from "../../assets/icons/Node.png";
import Postman from "../../assets/icons/Postman.png";
import ReactImg from "../../assets/icons/ReactImg.png";
import SASS from "../../assets/icons/SASS.png";

const ProjectsPage = () => {
  const API_URL = process.env.REACT_APP_API_URL;
  const [show, setShow] = useState({
    capstone: false,
    trivia: false,
    pokemon: false,
    todo: false,
    publicAPI: false,
    ELO: false,
  });
  const [showImg, setShowimg] = useState({});
  const projectList = [
    {
      title:
        "BrainStation - Capstone Project - Consulting Engineering Project Management App",
      description:
        "A full-stack project management application that allows project managers to keep track of their project budgets, allow them to control project budget overruns early on and manage their activities. The functionalities include a timesheet tracker, a projects tracker, and activity tracker for each individual user.",
      state: "capstone",
    },
    {
      title: "BrainStation - Hackathon - Computer Geek Trivia Challenge!",
      description:
        "A basic DOM application utilizing external API for creating a computer trivia challenge. Looking to test how much you know about computers? Test yourself by passing this quiz!",
      state: "trivia",
    },
    {
      title: "BrainStation - Pair Programming Hackathon - Pokemon Quiz",
      description:
        "A full-stack pokemon quiz application that determines what kind of pokemon you are based on the questions answered. Try out and see what kind of Pokemon are you!",
      state: "pokemon",
    },
    {
      title: "To-Dos Tracker",
      description:
        "A full-stack task management application that allows project managers to keep track of their teams tasks, deadlines, and project history for their projects.",
      state: "todo",
    },
    {
      title: "Public API",
      description: "In Progress",
      state: "publicAPI",
    },
    {
      title: "ELO Software Consulting Inc.",
      description:
        "A software engineering company that offers custom software development services. Check out their website for more info!",
      state: "ELO",
    },
  ];
  const handleOpen = (state: string, toggle: boolean) => {
    setShow({ ...show, [state]: toggle });
  };
  const handleClose = (state: string, toggle: boolean) => {
    setShow({ ...show, [state]: toggle });
  };
  return (
    <main className="ProjectsPage">
      <div className="px-4 pt-5 my-5 text-center border-bottom">
        <h1 className="display-4 fw-bold text-body-emphasis ">
          Check Out My Projects!
        </h1>
        <div className="col-lg-6 mx-auto">
          <p className="lead mb-4">
            See what kind of cool projects I am working on! If you're interested
            in any of my projects and would like a demo, please reach out to me
            directly!
          </p>
        </div>
        <div className="overflow-hidden">
          <div className="container px-5"></div>
        </div>
      </div>
      <div className="container px-4 py-5" id="featured-3">
        <h2 className="pb-2 border-bottom text-center">Projects</h2>
        <div className="row g-4 py-5 row-cols-1 row-cols-lg-3">
          {projectList.map((project) => {
            return (
              <div className="feature col ProjectsPage__subSection">
                <h2 className="fw-normal">{project.title}</h2>
                <p>{project.description}</p>
                <Button
                  type="button"
                  variant="primary"
                  onClick={() => {
                    handleOpen(project.state, true);
                  }}
                >
                  Learn More »
                </Button>
              </div>
            );
          })}
        </div>

        <Modal
          show={show.capstone}
          onHide={() => {
            handleClose("capstone", false);
          }}
          size="xl"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              Consulting Engineering Project Management App
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h6>Link:</h6>
            <a href="https://consulting-project-management.pages.dev/">
              https://consulting-project-management.pages.dev/
            </a>
            <hr className="featurette-divider" />
            <h6>Description:</h6>
            <p>
              A full-stack project management application that allows project
              managers to keep track of their project budgets, allow them to
              control project budget overruns early on and manage their
              activities. The functionalities include a timesheet tracker, a
              projects tracker, and activity tracker for each individual user.
            </p>
            <hr className="featurette-divider" />
            <h6>{`Author(s):`}</h6>
            <p>Eric Lo</p>
            <hr className="featurette-divider" />
            <h6>{`Technologies Used:`}</h6>
            <img src={HTML} alt="HTML" width={"50"} height={"50"} />
            <img src={Javascript} alt="Javascript" width={"50"} height={"50"} />
            <img src={Postman} alt="Postman" width={"50"} height={"50"} />
            <p>Front-end:</p>
            <img src={ReactImg} alt="React" width={"50"} height={"50"} />
            <img src={CSS} alt="CSS" width={"50"} height={"50"} />
            <img src={SASS} alt="SASS" width={"50"} height={"50"} />
            <img src={Axios} alt="AXIOS" width={"50"} height={"50"} />
            <img src={Chart} alt="Chart" width={"50"} height={"50"} />
            <p>Back-end:</p>
            <img src={Node} alt="Node" width={"50"} height={"50"} />
            <img src={Dotenv} alt="Dotenv" width={"50"} height={"50"} />
            <img src={Express} alt="Express" width={"50"} height={"50"} />
            <img src={Jwt} alt="Jwt" width={"50"} height={"50"} />
            <img src={Knex} alt="Knex" width={"50"} height={"50"} />
            <img src={MySQL} alt="MySQL" width={"50"} height={"50"} />
            <p>API Used:</p>
            <p>None</p>
            <hr className="featurette-divider" />
            <h6>{`Challenges:`}</h6>
            <p>
              Designing the databases and data flow in the front-end and
              back-end was complex as well as integrating it with user logins
              and authorizations. Another challenge was to complete the capstone
              in 1 week.
            </p>
            <hr className="featurette-divider" />
            <h6>{`Future Implementations:`}</h6>
            <ul>
              <li>
                Add project manager timesheet approval/reject functionality.
              </li>
              <li>Add a priority task list with due dates.</li>
              <li>
                Add an admin role authorization, who can keep track of overall
                company performances.
              </li>
              <li>
                Add lower-level authorization (front line staff) with basic
                functionalities like (task and timesheet access only).
              </li>
              <li>
                Add more graphical interfaces, data charts, and client database.
              </li>
              <li>Include role level database for hourly rates.</li>
            </ul>
            <hr className="featurette-divider" />
            <h6>{`Pictures:`}</h6>
            <div className="Carousel__dimensions">
              <Carousel>
                <Carousel.Item interval={3000}>
                  <img
                    className="d-block w-100"
                    src={`${API_URL}/images/activetask.png`}
                    alt="Image One"
                  />
                  <Carousel.Caption></Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item interval={3000}>
                  <img
                    className="d-block w-100"
                    src={`${API_URL}/images/projectlist.png`}
                    alt="Image Two"
                  />
                  <Carousel.Caption></Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item interval={3000}>
                  <img
                    className="d-block w-100"
                    src={`${API_URL}/images/timesheet.png`}
                    alt="Image Three"
                  />
                  <Carousel.Caption></Carousel.Caption>
                </Carousel.Item>
              </Carousel>
            </div>
            <hr className="featurette-divider" />
            <h6>{`Demo Video:`}</h6>
            <video
              src={`${API_URL}/images/Capstone.mp4`}
              controls
              width={"100%"}
              height={"100%"}
            ></video>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => {
                handleClose("capstone", false);
              }}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={show.trivia}
          onHide={() => {
            handleClose("trivia", false);
          }}
          size="xl"
        >
          <Modal.Header closeButton>
            <Modal.Title>Computer Geek Trivia Challenge!</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h6>Link:</h6>
            <a href="https://computer-geek-trivia-challenge.pages.dev/">
              https://computer-geek-trivia-challenge.pages.dev/
            </a>
            <hr className="featurette-divider" />
            <h6>Description:</h6>
            <p>
              A basic DOM application utilizing external API for creating a
              computer trivia challenge. Looking to test how much you know about
              computers? Test yourself by passing this quiz!
            </p>
            <hr className="featurette-divider" />
            <h6>{`Author(s):`}</h6>
            <p>Eric Lo</p>
            <hr className="featurette-divider" />
            <h6>{`Technologies Used:`}</h6>
            <img src={HTML} alt="HTML" width={"50"} height={"50"} />
            <img src={Javascript} alt="Javascript" width={"50"} height={"50"} />
            <img src={Postman} alt="Postman" width={"50"} height={"50"} />
            <p>Front-end:</p>
            <img src={CSS} alt="CSS" width={"50"} height={"50"} />
            <img src={SASS} alt="SASS" width={"50"} height={"50"} />
            <img src={Axios} alt="AXIOS" width={"50"} height={"50"} />
            <p>Back-end:</p>
            None
            <p>API Used:</p>
            <p>Open Trivia Database: https://opentdb.com</p>
            <hr className="featurette-divider" />
            <h6>{`Challenges:`}</h6>
            <p>
              Challenge was to complete a basic application in 24 hours for the
              hackathon using an external API. Challenges included understanding
              the end-points, documentations, manipulating JSON data to generate
              random questions, and building the logic of the application.
            </p>
            <hr className="featurette-divider" />
            <h6>{`Future Implementations:`}</h6>
            <ul>
              <li>
                Add drop down functionality to allow user to determine how many
                questions to generate.
              </li>
              <li>
                Add drop down functionality to alolow user to choose a category
                to generate questions from.
              </li>
            </ul>
            <hr className="featurette-divider" />
            <h6>{`Pictures:`}</h6>
            <div className="Carousel__dimensions">
              <Carousel>
                <Carousel.Item interval={3000}>
                  <img
                    className="d-block w-100"
                    src={`${API_URL}/images/HackathonQuestion.png`}
                    alt="Image One"
                  />
                  <Carousel.Caption></Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item interval={3000}>
                  <img
                    className="d-block w-100"
                    src={`${API_URL}/images/HackathonSubmittedFailed.png`}
                    alt="Image Two"
                  />
                  <Carousel.Caption></Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item interval={3000}>
                  <img
                    className="d-block w-100"
                    src={`${API_URL}/images/HackathonSubmittedPassed1.png`}
                    alt="Image Three"
                  />
                  <Carousel.Caption></Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item interval={3000}>
                  <img
                    className="d-block w-100"
                    src={`${API_URL}/images/HackathonSubmittedPassed2.png`}
                    alt="Image Four"
                  />
                  <Carousel.Caption></Carousel.Caption>
                </Carousel.Item>
              </Carousel>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => {
                handleClose("trivia", false);
              }}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={show.pokemon}
          onHide={() => {
            handleClose("pokemon", false);
          }}
          size="xl"
        >
          <Modal.Header closeButton>
            <Modal.Title>Pokemon Quiz</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h6>Description:</h6>
            <p>
              A full-stack pokemon quiz application that determines what kind of
              pokemon you are based on the questions answered. Try out and see
              what kind of Pokemon are you!
            </p>
            <hr className="featurette-divider" />
            <h6>{`Author(s):`}</h6>
            <p>Eric Lo, Dimitrios Ellindis, Matthew Staples</p>
            <hr className="featurette-divider" />
            <h6>{`Technologies Used:`}</h6>
            <img src={HTML} alt="HTML" width={"50"} height={"50"} />
            <img src={Javascript} alt="Javascript" width={"50"} height={"50"} />
            <img src={Postman} alt="Postman" width={"50"} height={"50"} />
            <p>Front-end:</p>
            <img src={ReactImg} alt="React" width={"50"} height={"50"} />
            <img src={CSS} alt="CSS" width={"50"} height={"50"} />
            <img src={SASS} alt="SASS" width={"50"} height={"50"} />
            <img src={Axios} alt="AXIOS" width={"50"} height={"50"} />
            <p>Back-end:</p>
            <img src={Node} alt="Node" width={"50"} height={"50"} />
            <img src={Dotenv} alt="Dotenv" width={"50"} height={"50"} />
            <img src={Express} alt="Express" width={"50"} height={"50"} />
            <p>API Used:</p>
            <p>Pokeapi: https://pokeapi.co/api/v2/generation/2</p>
            <hr className="featurette-divider" />
            <h6>{`Challenges:`}</h6>
            <p>
              Challenge was to complete a full stack application in 24 hours for
              the pair programming hackathon. Challenges included understanding
              the API end-points, documentations, manipulating JSON data,
              creating own back-end server with questions, and creating logic
              for determining what type pokemon you are. Another challenge is
              learning how to work with your teammates such as reviewing a pull
              request on GitHub.
            </p>
            <hr className="featurette-divider" />
            <h6>{`Future Implementations:`}</h6>
            <ul>
              <li>
                Implement a random pokemon generator of generation 2 pokemon
                based on the result from the questionaire
              </li>
              <li>
                Display all pokemon attributes from the API after hovering over
                each pokemon
              </li>
            </ul>
            <hr className="featurette-divider" />
            <h6>{`Pictures:`}</h6>
            <div className="Carousel__dimensions">
              <Carousel>
                <Carousel.Item interval={3000}>
                  <img
                    className="d-block w-100"
                    src={`${API_URL}/images/PokemonQuiz.png`}
                    alt="Image One"
                  />
                  <Carousel.Caption></Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item interval={3000}>
                  <img
                    className="d-block w-100"
                    src={`${API_URL}/images/PokemonQuizSubmitted.png`}
                    alt="Image Two"
                  />
                  <Carousel.Caption></Carousel.Caption>
                </Carousel.Item>
              </Carousel>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => {
                handleClose("pokemon", false);
              }}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={show.todo}
          onHide={() => {
            handleClose("todo", false);
          }}
          size="xl"
        >
          <Modal.Header closeButton>
            <Modal.Title>To-Dos-Tracker</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h6>Link:</h6>
            <a href="https://to-dos-tracker.pages.dev/">
              https://to-dos-tracker.pages.dev/
            </a>
            <hr className="featurette-divider" />
            <h6>Description:</h6>
            <p>
              A full-stack task management application that allows project
              managers to keep track of their teams tasks, deadlines, and
              project history for their projects.
            </p>
            <hr className="featurette-divider" />
            <h6>{`Author(s):`}</h6>
            <p>Eric Lo</p>
            <hr className="featurette-divider" />
            <h6>{`Technologies Used:`}</h6>
            <img src={HTML} alt="HTML" width={"50"} height={"50"} />
            <img src={Javascript} alt="Javascript" width={"50"} height={"50"} />
            <img src={Postman} alt="Postman" width={"50"} height={"50"} />
            <p>Front-end:</p>
            <img src={ReactImg} alt="React" width={"50"} height={"50"} />
            <img src={CSS} alt="CSS" width={"50"} height={"50"} />
            <img src={SASS} alt="SASS" width={"50"} height={"50"} />
            <img src={Axios} alt="AXIOS" width={"50"} height={"50"} />
            <p>Back-end:</p>
            <img src={Node} alt="Node" width={"50"} height={"50"} />
            <img src={Dotenv} alt="Dotenv" width={"50"} height={"50"} />
            <img src={Express} alt="Express" width={"50"} height={"50"} />
            <img src={Jwt} alt="Jwt" width={"50"} height={"50"} />
            <img src={Knex} alt="Knex" width={"50"} height={"50"} />
            <img src={MySQL} alt="MySQL" width={"50"} height={"50"} />
            <p>API Used:</p>
            <p>None</p>
            <hr className="featurette-divider" />
            <h6>{`Challenges:`}</h6>
            <p>
              Challenge was to complete a full stack application that tracks
              tasks and project deadlines for project managers. Some challenges
              included coming up with a design specification as to how this
              application should work. Coming up with role access level for each
              user and what is accessible.
            </p>
            <hr className="featurette-divider" />
            <h6>{`Future Implementations:`}</h6>
            <ul>
              <li>
                Implement a pile of tasks for all users which then anybody can
                accept from project managers
              </li>
              <li>
                Add a project hours tracker for committed and actual for each
                task and put a log in the project cards
              </li>
              <li>
                Add a milestone and tasks section in the projects card for
                keeping track of tasks for each milestone phase of a project
              </li>
              <li>
                Create a graphical project gantt chart for each milestone and
                task
              </li>
            </ul>
            <hr className="featurette-divider" />
            <h6>{`Pictures:`}</h6>
            <div className="Carousel__dimensions">
              <Carousel>
                <Carousel.Item interval={3000}>
                  <img
                    className="d-block w-100"
                    src={`${API_URL}/images/ToDosCalendar.png`}
                    alt="Image One"
                  />
                  <Carousel.Caption></Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item interval={3000}>
                  <img
                    className="d-block w-100"
                    src={`${API_URL}/images/ToDosCalendarModal.png`}
                    alt="Image One"
                  />
                  <Carousel.Caption></Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item interval={3000}>
                  <img
                    className="d-block w-100"
                    src={`${API_URL}/images/ToDosProfile.png`}
                    alt="Image One"
                  />
                  <Carousel.Caption></Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item interval={3000}>
                  <img
                    className="d-block w-100"
                    src={`${API_URL}/images/ToDosProjects.png`}
                    alt="Image One"
                  />
                  <Carousel.Caption></Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item interval={3000}>
                  <img
                    className="d-block w-100"
                    src={`${API_URL}/images/ToDosProjectsCard.png`}
                    alt="Image One"
                  />
                  <Carousel.Caption></Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item interval={3000}>
                  <img
                    className="d-block w-100"
                    src={`${API_URL}/images/ToDosTasks.png`}
                    alt="Image One"
                  />
                  <Carousel.Caption></Carousel.Caption>
                </Carousel.Item>
              </Carousel>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => {
                handleClose("todo", false);
              }}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={show.publicAPI}
          onHide={() => {
            handleClose("publicAPI", false);
          }}
          size="xl"
        >
          <Modal.Header closeButton>
            <Modal.Title>Public API</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h6>Link:</h6>
            <a href="https://public-api-news.pages.dev/">
              https://public-api-news.pages.dev/
            </a>
            <hr className="featurette-divider" />
            <h6>Description:</h6>
            <p>In Progress</p>
            <hr className="featurette-divider" />
            <h6>{`Author(s):`}</h6>
            <p>Eric Lo</p>
            <hr className="featurette-divider" />
            <h6>{`Technologies Used:`}</h6>
            <img src={HTML} alt="HTML" width={"50"} height={"50"} />
            <img src={Javascript} alt="Javascript" width={"50"} height={"50"} />
            <img src={Postman} alt="Postman" width={"50"} height={"50"} />
            <p>Front-end:</p>
            <img src={ReactImg} alt="React" width={"50"} height={"50"} />
            <img src={CSS} alt="CSS" width={"50"} height={"50"} />
            <img src={SASS} alt="SASS" width={"50"} height={"50"} />
            <img src={Axios} alt="AXIOS" width={"50"} height={"50"} />
            <p>Back-end:</p>
            <img src={Node} alt="Node" width={"50"} height={"50"} />
            <img src={Dotenv} alt="Dotenv" width={"50"} height={"50"} />
            <img src={Express} alt="Express" width={"50"} height={"50"} />
            <img src={Jwt} alt="Jwt" width={"50"} height={"50"} />
            <img src={Knex} alt="Knex" width={"50"} height={"50"} />
            <img src={MySQL} alt="MySQL" width={"50"} height={"50"} />
            <p>API Used:</p>
            <p>None</p>
            <hr className="featurette-divider" />
            <h6>{`Challenges:`}</h6>
            <p>In Progress</p>
            <hr className="featurette-divider" />
            <h6>{`Future Implementations:`}</h6>
            <ul>
              <li>In Progress</li>
            </ul>
            <hr className="featurette-divider" />
            <h6>{`Pictures:`}</h6>
            <div className="Carousel__dimensions">
              <Carousel></Carousel>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => {
                handleClose("publicAPI", false);
              }}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={show.ELO}
          onHide={() => {
            handleClose("ELO", false);
          }}
          size="xl"
        >
          <Modal.Header closeButton>
            <Modal.Title>ELO Software Consulting Inc.</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h6>Link:</h6>
            <a href="https://elo-software-consulting-inc.pages.dev/">
              https://elo-software-consulting-inc.pages.dev/
            </a>
            <hr className="featurette-divider" />
            <h6>Description:</h6>
            <p>In Progress</p>
            <hr className="featurette-divider" />
            <h6>{`Author(s):`}</h6>
            <p>Eric Lo</p>
            <hr className="featurette-divider" />
            <h6>{`Technologies Used:`}</h6>
            <img src={HTML} alt="HTML" width={"50"} height={"50"} />
            <img src={Javascript} alt="Javascript" width={"50"} height={"50"} />
            <img src={Postman} alt="Postman" width={"50"} height={"50"} />
            <p>Front-end:</p>
            <img src={ReactImg} alt="React" width={"50"} height={"50"} />
            <img src={CSS} alt="CSS" width={"50"} height={"50"} />
            <img src={SASS} alt="SASS" width={"50"} height={"50"} />
            <img src={Axios} alt="AXIOS" width={"50"} height={"50"} />
            <p>Back-end:</p>
            <img src={Node} alt="Node" width={"50"} height={"50"} />
            <img src={Dotenv} alt="Dotenv" width={"50"} height={"50"} />
            <img src={Express} alt="Express" width={"50"} height={"50"} />
            <img src={Jwt} alt="Jwt" width={"50"} height={"50"} />
            <img src={Knex} alt="Knex" width={"50"} height={"50"} />
            <img src={MySQL} alt="MySQL" width={"50"} height={"50"} />
            <p>API Used:</p>
            <p>None</p>
            <hr className="featurette-divider" />
            <h6>{`Challenges:`}</h6>
            <p>In Progress</p>
            <hr className="featurette-divider" />
            <h6>{`Future Implementations:`}</h6>
            <ul>
              <li>In Progress</li>
            </ul>
            <hr className="featurette-divider" />
            <h6>{`Pictures:`}</h6>
            <div className="Carousel__dimensions">
              <Carousel></Carousel>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => {
                handleClose("ELO", false);
              }}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
      <hr className="featurette-divider" />
    </main>
  );
};

export default ProjectsPage;
