// import React from "react";
import * as React from "react";
import "./AboutPage.scss";
import { Button } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { useState } from "react";
import EducationIcon from "../../assets/icons/education.png";
import HobbiesIcon from "../../assets/icons/hobbies.png";
import SkillsIcon from "../../assets/icons/skills.png";
import LanguageIcon from "../../assets/icons/languages.png";
import CertificatesIcon from "../../assets/icons/certificates.png";
import OrganizationsIcon from "../../assets/icons/organizations.png";
import axios from "axios";

const AboutPage = () => {
  const API_URL = process.env.REACT_APP_API_URL;
  const [show, setShow] = useState({
    education: false,
    hobbies: false,
    skills: false,
    licensesCertifications: false,
    organizations: false,
    languages: false,
  });
  const [showImg, setShowimg] = useState({
    cplus: false,
    brainstation: false,
    c: false,
    industry: false,
    dataAlg: false,
    peng: false,
    uvic: false,
  });
  const aboutMeList = [
    {
      img: EducationIcon,
      alt: "Education Icon",
      title: "Education",
      description:
        "Want to find out more about my education background? Please click on the below button.",
      state: "education",
    },
    {
      img: HobbiesIcon,
      alt: "Hobbies Icon",
      title: "Hobbies",
      description:
        "Want to find out more about my hobbies? Please click on the below button.",
      state: "hobbies",
    },
    {
      img: SkillsIcon,
      alt: "Skills Icon",
      title: "Skills",
      description:
        "Want to find out more about my skillsets? Please click on the below button.",
      state: "skills",
    },
    {
      img: CertificatesIcon,
      alt: "Licenses Certifications Icon",
      title: "Licenses and Certifications",
      description:
        "Want to find out more about my professional licenses and certifications? Please click on the below button.",
      state: "licensesCertifications",
    },
    {
      img: OrganizationsIcon,
      alt: "Organizations Icon",
      title: "Organizations",
      description:
        "Want to find out more about what organizations I'm involved with? Please click on the below button.",
      state: "organizations",
    },
    {
      img: LanguageIcon,
      alt: "Languages Icon",
      title: "Languages",
      description:
        " Want to find out more about what languages I speak? Please click on the below button.",
      state: "languages",
    },
  ];
  const handleOpen = (state: string, toggle: boolean) => {
    setShow({ ...show, [state]: toggle });
  };
  const handleClose = (state: string, toggle: boolean) => {
    setShow({ ...show, [state]: toggle });
  };
  const handleOpenImg = (state: string, toggle: boolean) => {
    setShowimg({ ...showImg, [state]: toggle });
  };
  const handleCloseImg = (state: string, toggle: boolean) => {
    setShowimg({ ...showImg, [state]: toggle });
  };
  // const onButtonClickResume = () => {
  //   const pdfUrl = `${API_URL}/images/Resume.png`;
  //   const link = document.createElement("a");
  //   // 👇️ set to relative path
  //   link.href = pdfUrl;
  //   link.download = "Resume.png";
  //   document.body.appendChild(link);
  //   link.click();
  //   document.body.removeChild(link);
  // };
  const downloadSoftwareResumePDF = () => {
    axios({
      url: `${API_URL}software-resume`, // Endpoint to download PDF on the server
      method: "GET",
      responseType: "blob", // Important
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Eric_Lo - Software Resume.pdf");
      document.body.appendChild(link);
      link.click();
    });
  };

  const downloadElectricalResumePDF = () => {
    axios({
      url: `${API_URL}electrical-resume`, // Endpoint to download PDF on the server
      method: "GET",
      responseType: "blob", // Important
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Eric_Lo - Electrical Engineer Resume.pdf");
      document.body.appendChild(link);
      link.click();
    });
  };

  // console.log(show);
  return (
    <main className="AboutPage">
      <div className="px-4 pt-5 my-5 border-bottom">
        <h1 className="display-4 fw-bold text-body-emphasis text-center">
          Who Am I?
        </h1>
        <div className="col-lg-6 mx-auto">
          <p className="lead mb-4">
            Hello! My name is Eric Lo, and I am a Professional Engineer (P.Eng.)
            specializing in electrical engineering, based in Burnaby, British
            Columbia. I have a strong passion for software engineering and
            development, particularly as a full-stack developer, with a keen
            interest in artificial intelligence (AI) and machine learning. I
            thrive on solving complex problems and creating tools to enhance
            efficiency. I am always eager to learn new things and embrace new
            challenges.
          </p>
        </div>
        <div className="overflow-hidden">
          <div className="container px-5 text-center"></div>
        </div>
      </div>
      <div className="container px-4 py-5 my-5 text-center" id="featured-3">
        <h2 className="pb-2 border-bottom">About Me</h2>
        <div className="row g-4 py-5 row-cols-1 row-cols-lg-3">
          {aboutMeList.map((aboutMe) => {
            return (
              <div className="feature col AboutPage__subSection">
                <div className="feature-icon d-inline-flex align-items-center justify-content-center text-bg-primary bg-black fs-2 mb-3">
                  <img src={aboutMe.img} alt={aboutMe.alt} />
                </div>
                <h2 className="fw-normal">{aboutMe.title}</h2>
                <p>{aboutMe.description}</p>
                <Button
                  type="button"
                  variant="primary"
                  onClick={() => {
                    handleOpen(aboutMe.state, true);
                  }}
                >
                  Learn More »
                </Button>
              </div>
            );
          })}
        </div>

        <Modal
          show={show.education}
          onHide={() => {
            handleClose("education", false);
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>My Education</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h4>BrainStation</h4>
            <h6>Diploma, Software Engineering</h6>
            <p>AUG 2023 - OCT 2023, BURNABY, BC</p>
            <hr className="featurette-divider" />
            <h4>University of Victoria</h4>
            <h6>Bachelors in Engineering, Electrical Engineering</h6>
            <p>SEPT 2010 - NOV 2016, VICTORIA, BC</p>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => {
                handleClose("education", false);
              }}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={show.hobbies}
          onHide={() => {
            handleClose("hobbies", false);
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>My Hobbies</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {" "}
            <h4>Badminton</h4>
            <p>
              {" "}
              I play badminton regularly on weekends at Edmonds Community
              Center.
            </p>
            <hr className="featurette-divider" />
            <h4>Coding</h4>
            <p>
              I practice LeetCode problems regularly and enjoy solving data
              structure and algorithm type questions. Check out my LeetCode
              profile!
            </p>
            <hr className="featurette-divider" />
            <h4>Video Games</h4>
            <p>
              I play League of Legends most of the time during break times. My
              favorite role is middle lane or support.
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => {
                handleClose("hobbies", false);
              }}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={show.skills}
          onHide={() => {
            handleClose("skills", false);
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>My Skills</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {" "}
            <h4>Softwares</h4>
            <p>
              {" "}
              Visual Studio Code, GitHuB, Slack, Jira, Figma, MySQL, Loom,
              Heroku
            </p>
            <hr className="featurette-divider" />
            <h4>Programming Languages</h4>
            <p> C, C++, JavaScript, TypeScript, SQL</p>
            <hr className="featurette-divider" />
            <h4>Front-End Skills</h4>
            <p>
              HTML, CSS, SASS, AXIOS, POSTMAN API, React, Vite, React Bootstrap,
              Bootstrap, MUI, jQuery, Chart.js, Calendar.js
            </p>
            <hr className="featurette-divider" />
            <h4>Back-End Skills</h4>
            <p>Express.js, Node.js, Knex.js, Bcrypt, JWT, Cors, MySQL</p>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => {
                handleClose("skills", false);
              }}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={show.licensesCertifications}
          onHide={() => {
            handleClose("licensesCertifications", false);
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              My Professional License and Certifications
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h4>Software Engineering Diploma</h4>
            <h6>BrainStation</h6>
            <Button
              type="button"
              variant="secondary"
              onClick={() => {
                handleOpenImg("brainstation", true);
              }}
            >
              See Credentials
            </Button>
            <Modal
              show={showImg.brainstation}
              onHide={() => {
                handleCloseImg("brainstation", false);
              }}
              size="xl"
            >
              <Modal.Header closeButton>
                {" "}
                <Modal.Title>Certificate</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <img
                  src={`${API_URL}/images/BrainStation Diploma.png`}
                  width="100%"
                  height="100%"
                  alt="BrainStation Diploma"
                />
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="secondary"
                  onClick={() => {
                    handleCloseImg("brainstation", false);
                  }}
                >
                  Close
                </Button>
              </Modal.Footer>
            </Modal>{" "}
            <hr className="featurette-divider" />
            <h4>Bachelors Degree in Engineering, Electrical Engineering</h4>
            <h6>University of Victoria</h6>
            <Button
              type="button"
              variant="secondary"
              onClick={() => {
                handleOpenImg("uvic", true);
              }}
            >
              See Credentials
            </Button>
            <Modal
              show={showImg.uvic}
              onHide={() => {
                handleCloseImg("uvic", false);
              }}
              size="xl"
            >
              <Modal.Header closeButton>
                {" "}
                <Modal.Title>Certificate</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <img
                  src={`${API_URL}/images/UVic Degree.jpg`}
                  width="100%"
                  height="100%"
                  alt="UVic Degree"
                />
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="secondary"
                  onClick={() => {
                    handleCloseImg("uvic", false);
                  }}
                >
                  Close
                </Button>
              </Modal.Footer>
            </Modal>
            <hr className="featurette-divider" />
            <h4>Industry Challenge</h4>
            <h6>BrainStation and Scotiabank</h6>
            <Button
              type="button"
              variant="secondary"
              onClick={() => {
                handleOpenImg("industry", true);
              }}
            >
              See Credentials
            </Button>
            <Modal
              show={showImg.industry}
              onHide={() => {
                handleCloseImg("industry", false);
              }}
              size="xl"
            >
              <Modal.Header closeButton>
                {" "}
                <Modal.Title>Certificate</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <img
                  src={`${API_URL}/images/Industry Challenge.png`}
                  width="100%"
                  height="100%"
                  alt="Industry Challenge Certificate"
                />
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="secondary"
                  onClick={() => {
                    handleCloseImg("industry", false);
                  }}
                >
                  Close
                </Button>
              </Modal.Footer>
            </Modal>
            <hr className="featurette-divider" />
            <h4>Professional Engineering License</h4>
            <h6>Engineers and Geoscientists BC</h6>
            <Button
              type="button"
              variant="secondary"
              onClick={() => {
                handleOpenImg("peng", true);
              }}
            >
              See Credentials
            </Button>
            <Modal
              show={showImg.peng}
              onHide={() => {
                handleCloseImg("peng", false);
              }}
              size="xl"
            >
              <Modal.Header closeButton>
                {" "}
                <Modal.Title>Certificate</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <img
                  src={`${API_URL}/images/P.Eng. Certificate.jpg`}
                  width="100%"
                  height="100%"
                  alt="Professional Engineer Certificate"
                />
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="secondary"
                  onClick={() => {
                    handleCloseImg("peng", false);
                  }}
                >
                  Close
                </Button>
              </Modal.Footer>
            </Modal>
            <hr className="featurette-divider" />
            <h4>Begginning C++ Programming - From Beginner to Beyond</h4>
            <h6>Udemy</h6>
            <Button
              type="button"
              variant="secondary"
              onClick={() => {
                handleOpenImg("cplus", true);
              }}
            >
              See Credentials
            </Button>
            <Modal
              show={showImg.cplus}
              onHide={() => {
                handleCloseImg("cplus", false);
              }}
              size="xl"
            >
              <Modal.Header closeButton>
                {" "}
                <Modal.Title>Certificate</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <img
                  src={`${API_URL}/images/Beginning C++ Programming - From Beginner to Beyond.jpg`}
                  width="100%"
                  height="100%"
                  alt="C++ Certificate"
                />
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="secondary"
                  onClick={() => {
                    handleCloseImg("cplus", false);
                  }}
                >
                  Close
                </Button>
              </Modal.Footer>
            </Modal>
            <hr className="featurette-divider" />
            <h4>C Programming For Beginners - Master the C Language</h4>
            <h6>Udemy</h6>
            <Button
              type="button"
              variant="secondary"
              onClick={() => {
                handleOpenImg("c", true);
              }}
            >
              See Credentials
            </Button>
            <Modal
              show={showImg.c}
              onHide={() => {
                handleCloseImg("c", false);
              }}
              size="xl"
            >
              <Modal.Header closeButton>
                {" "}
                <Modal.Title>Certificate</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <img
                  src={`${API_URL}/images/C Programming For Begginers - Master the C Language.jpg`}
                  width="100%"
                  height="100%"
                  alt="C Certificate"
                />
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="secondary"
                  onClick={() => {
                    handleCloseImg("c", false);
                  }}
                >
                  Close
                </Button>
              </Modal.Footer>
            </Modal>
            <hr className="featurette-divider" />
            <h4>Mastering Data Structures & Algorithms using C and C++</h4>
            <h6>Udemy</h6>
            <Button
              type="button"
              variant="secondary"
              onClick={() => {
                handleOpenImg("dataAlg", true);
              }}
            >
              See Credentials
            </Button>
            <Modal
              show={showImg.dataAlg}
              onHide={() => {
                handleCloseImg("dataAlg", false);
              }}
              size="xl"
            >
              <Modal.Header closeButton>
                {" "}
                <Modal.Title>Certificate</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <img
                  src={`${API_URL}/images/Mastering Data Structure and Algorithm using C and C++.jpg`}
                  width="100%"
                  height="100%"
                  alt="Data Structures and Algorithms Certificate"
                />
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="secondary"
                  onClick={() => {
                    handleCloseImg("dataAlg", false);
                  }}
                >
                  Close
                </Button>
              </Modal.Footer>
            </Modal>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => {
                handleClose("licensesCertifications", false);
              }}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={show.organizations}
          onHide={() => {
            handleClose("organizations", false);
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>My Organizations</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h4>Engineers and Geoscientists BC</h4>
            <p>
              As a registered professional engineer through EGBC, I am involved
              with continuing education and keeping up to professional law and
              ethics.
            </p>
            <hr className="featurette-divider" />
            <h4>International Municipal Signal Association BC</h4>
            <p>
              As a member of IMSA BC Section, I attend events and courses for
              updates in public safety.
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => {
                handleClose("organizations", false);
              }}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={show.languages}
          onHide={() => {
            handleClose("languages", false);
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>My Languages</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h4>English</h4>
            <p>Proficient</p>
            <hr className="featurette-divider" />
            <h4>Cantonese</h4>
            <p>Fluent</p>
            <hr className="featurette-divider" />
            <h4>French</h4>
            <p>Novice</p>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => {
                handleClose("languages", false);
              }}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>

      <hr className="featurette-divider" />

      <div className="container px-4 py-5 my-5 ">
        <h2 className="pb-2 border-bottom text-center">My Work Experience</h2>
        <div className="row featurette">
          <div className="col-md-7">
            <h2 className="featurette-heading fw-normal lh-1">
              TWD Technologies,
              <span className="text-body-secondary">
                {" "}
                Senior Electrical Engineer
              </span>
            </h2>
            <ul className="AboutPage__TWD-List">
              <li>
                <p className="lead">
                  Managed and lead a team of 2-4 designers, drafters, and
                  technologists for 10+ transportation projects per month by
                  acting as Engineer of Record (EOR) and project manager.
                </p>
              </li>
              <li>
                <p className="lead">
                  Drafted 10-20 project proposals per month for a wide variety
                  of transportation clients, achieving and maintaining a 60% win
                  rate.
                </p>
              </li>
              <li>
                <p className="lead">
                  Achieved $300,000 in target sales within one fiscal year.
                </p>
              </li>
            </ul>
          </div>
          <div className="col-md-5">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2603.590254856401!2d-123.00074012351205!3d49.26521177139018!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x548677d657df3e8f%3A0x9371cb89e083482b!2sTWD%20Technologies!5e0!3m2!1sen!2sca!4v1699460655686!5m2!1sen!2sca"
              width="600"
              height="450"
              loading="lazy"
            ></iframe>
          </div>
        </div>

        <hr className="featurette-divider" />

        <div className="row featurette">
          <div className="col-md-7 order-md-2">
            <h2 className="featurette-heading fw-normal lh-1">
              PBX Engineering,{" "}
              <span className="text-body-secondary">Design Engineer</span>
            </h2>
            <ul className="AboutPage__PBX-List">
              <li>
                <p className="lead">
                  Worked both independently and in a team setting to design 5-10
                  transportation electrical projects per month
                </p>
              </li>
              <li>
                <p className="lead">
                  Co-ordinated daily with 4-5 other departments to develop
                  designs that best met client expectations and satisfied all
                  regulations.
                </p>
              </li>
              <li>
                <p className="lead">
                  Lead 3-5 client meetings per week to co-ordinate design
                  changes, provide status updates, and manage client
                  expectations.
                </p>
              </li>
            </ul>
          </div>
          <div className="col-md-5 order-md-1">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2602.592564999212!2d-123.1119280080786!3d49.284117096899934!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x54867178332ec2db%3A0xded5c0f000f4cc3e!2sPBX%20Engineering%20Ltd.!5e0!3m2!1sen!2sca!4v1699460957307!5m2!1sen!2sca"
              width="600"
              height="450"
              loading="lazy"
            ></iframe>
          </div>
        </div>

        <hr className="featurette-divider" />

        <div className="row featurette">
          <div className="col-md-7">
            <h2 className="featurette-heading fw-normal lh-1">
              Great Northern Engineering Consultants,{" "}
              <span className="text-body-secondary">Electrical Designer</span>
            </h2>
            <ul className="AboutPage__GNEC-List">
              <li>
                <p className="lead">
                  Trained and mentored 3-4 drafters, technologists, and
                  Engineers-in-Training (EITs) who became competent and
                  efficient employees that were able to meet project budgets.
                </p>
              </li>
              <li>
                <p className="lead">
                  Performed construction services and site inspections to ensure
                  designs were being followed and standards for safety were met.
                </p>
              </li>
            </ul>
          </div>
          <div className="col-md-5">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d83324.87578418697!2d-123.00637537329465!3d49.2592964!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x548679d2ba02433b%3A0xf05d009474ecb35d!2sGreat%20Northern%20Engineering%20Consultants%20Inc.!5e0!3m2!1sen!2sca!4v1699461273936!5m2!1sen!2sca"
              width="600"
              height="450"
              loading="lazy"
            ></iframe>
          </div>
        </div>
      </div>
      <hr className="featurette-divider" />
      <div className="container px-4 py-5 my-5 text-center" id="featured-3">
        <h2 className="pb-2 border-bottom">My Resume</h2>
        <h6>
          Click on below button to download my{" "}
          <strong>"Software Resume"</strong>
        </h6>
        {/* <Button type="button" variant="secondary" onClick={onButtonClickResume}>
          View Resume
        </Button> */}
        <Button
          type="button"
          variant="primary"
          onClick={downloadSoftwareResumePDF}
        >
          Download
        </Button>
        <h6>
          Click on below button to download my{" "}
          <strong>"Electrical Engineer Resume"</strong>
        </h6>
        <Button
          type="button"
          variant="primary"
          onClick={downloadElectricalResumePDF}
        >
          Download
        </Button>
      </div>
      <hr className="featurette-divider" />
    </main>
  );
};

export default AboutPage;
