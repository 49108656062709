// import React from "react";
import * as React from "react";
import "./ContactPage.scss";
import { useState } from "react";
// import { useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
// import InputGroup from "react-bootstrap/InputGroup";
import Row from "react-bootstrap/Row";
import emailjs from "@emailjs/browser";
import Modal from "react-bootstrap/Modal";

const ContactPage = () => {
  const [validated, setValidated] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => {
    window.location.reload();
    setShow(false);
  };
  // const handleShow = () => setShow(true);
  const SERVICE_ID = process.env.REACT_APP_SERVICE_ID;
  const TEMPLATE_ID = process.env.REACT_APP_TEMPLATE_ID;
  const PUBLIC_KEY = process.env.REACT_APP_PUBLIC_KEY;
  const API_URL = process.env.REACT_APP_API_URL;
  // const Navigate = useNavigate();

  const handleSubmit = (event: any) => {
    const form = event.currentTarget;
    // console.log(form);

    if (form.checkValidity() === false) {
      // console.log("Form Errors");
      event.preventDefault();
      event.stopPropagation();
    } else {
      // console.log("Form submitted");
      // setValidated(true);
      emailjs.sendForm(
        `${SERVICE_ID}`,
        `${TEMPLATE_ID}`,
        event.target,
        `${PUBLIC_KEY}`
      );
      setShow(true);
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);
  };

  // console.log(SERVICE_ID);
  // console.log(TEMPLATE_ID);
  // console.log(PUBLIC_KEY);

  return (
    <main className="ContactPage">
      <div className="px-4 pt-5 my-5 text-center border-bottom">
        <h1 className="display-4 fw-bold text-body-emphasis">Let's Connect!</h1>
        <div className="col-lg-6 mx-auto">
          <p className="lead mb-4">
            Thank you for taking the time to visit my portfolio! Should you have
            any questions, feel free to reach out to me and I will get back to
            you as soon as possible!
          </p>
        </div>
      </div>
      <div className="overflow-hidden">
        <div className="container px-5">
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Row className="mb-3">
              <Form.Group as={Col} md="4" controlId="validationCustom01">
                <Form.Label>First name</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="First name"
                  defaultValue=""
                  name="first_name_from"
                />
                <Form.Control.Feedback type="invalid">
                  Enter a valid first name
                </Form.Control.Feedback>
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} md="4" controlId="validationCustom02">
                <Form.Label>Last name</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="Last name"
                  defaultValue=""
                  name="last_name_from"
                />
                <Form.Control.Feedback type="invalid">
                  Enter a valid last name
                </Form.Control.Feedback>
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} md="4" controlId="validationCustom03">
                <Form.Label>E-mail</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="E-mail"
                  defaultValue=""
                  name="e-mail_from"
                />
                <Form.Control.Feedback type="invalid">
                  Enter a valid e-mail
                </Form.Control.Feedback>
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group className="mb-3" controlId="validationCustom04">
                <Form.Label>Message</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  required
                  placeholder="Enter a message"
                  name="message_from"
                />
                <Form.Control.Feedback type="invalid">
                  Enter a valid message
                </Form.Control.Feedback>
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              </Form.Group>
            </Row>
            <div className="ContactPage__Container">
              <Button type="submit">Submit</Button>
            </div>
          </Form>
          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Status</Modal.Title>
            </Modal.Header>
            <Modal.Body>Message was sent!</Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
      <hr className="featurette-divider" />
    </main>
  );
};

export default ContactPage;
