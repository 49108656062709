// import React from "react";
import * as React from "react";
import "./HomePage.scss";
import { Link, useLocation } from "react-router-dom";
import AboutMeIcon from "../../assets/icons/aboutme.png";
import ContactMeIcon from "../../assets/icons/contactme.png";
import ProjectsIcon from "../../assets/icons/projects.png";

const HomePage = () => {
  const API_URL = process.env.REACT_APP_API_URL;
  const linkList = [
    {
      img: AboutMeIcon,
      alt: "About Me Icon",
      title: "About Me",
      description:
        "Want to find out more about me? Please click on the below button.",
      link: `/About`,
    },
    {
      img: ProjectsIcon,
      alt: "Projects Icon",
      title: "Projects",
      description:
        "Want to find out more about my cool projects? Please click on the below button",
      link: `/Projects`,
    },
    {
      img: ContactMeIcon,
      alt: "Contact Me Icon",
      title: "Contact Me",
      description: "Want to reach out to me? Please click on the below button.",
      link: `/Contact`,
    },
  ];

  return (
    <main className="HomePage">
      <div className="px-4 pt-5 my-5 border-bottom">
        <h1 className="display-4 fw-bold text-body-emphasis text-center">
          Hi, I'm Eric Lo
        </h1>
        <div className="col-lg-6 mx-auto HomePage__title">
          <h2 className="lead mb-4">
            Electrical Engineer and Full Stack Developer
          </h2>
        </div>
        <div className="overflow-hidden">
          <div className="container px-5 text-center">
            <img
              src={`${API_URL}/images/EricLo.jpg`}
              className="img-fluid border rounded-3 shadow-lg mb-4"
              alt="Example image"
              width="700"
              height="500"
              loading="lazy"
            />
          </div>
        </div>
      </div>
      <div className="row ">
        {linkList.map((link) => {
          return (
            <div className="col-lg-4 HomePage__subSection">
              <img src={link.img} alt={link.alt} />
              <h2 className="fw-normal">{link.title}</h2>
              <p>{link.description}</p>
              <p>
                <Link to={link.link} className="btn btn-primary">
                  Go To »
                </Link>
              </p>
            </div>
          );
        })}
      </div>
      <hr className="featurette-divider" />
    </main>
  );
};

export default HomePage;
